import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Neubrueck: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Neubrueck,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <polygon
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Neubrück"
          points="833,640 837,652 817,660 817,676 806,682 794,678 786,662 799,656 797,642 817,618 825,618 825,634"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 801.9199 641.8889)"
        >
          <tspan x="0" y="0">
            Neu-
          </tspan>
          <tspan x="0" y="14.4">
            brück
          </tspan>
        </text>
      </g>
    </>
  );
});
